<template>
  <div :class="{ 'user-profile--horiz': horizontal }" class="user-profile">
    <form aria-label="Change user details" @submit.prevent="onSubmit">
      <div class="user-profile__form-inner">
        <div class="user-profile__select">
          <label
            v-if="residencyValues"
            for="profile-residency"
            aria-label="Change residency"
          >
            {{ $t('component.user-profile.labels.residency') | capitalize }}
          </label>

          <select
            v-if="residencyValues"
            id="profile-residency"
            ref="year"
            v-model="profile.residency"
            data-test="profile-residency"
            name="profile_residency"
            class="styled-select"
          >
            <option
              v-for="(value, index) in residencyValues"
              :id="'residency_value_' + index"
              :key="index"
              :value="index"
            >
              {{ value }}
            </option>
          </select>
        </div>

        <div v-if="hasQualification" class="user-profile__select">
          <label
            for="1842_profile_qualification"
            aria-label="Change qualification"
          >
            {{ $t('component.user-profile.labels.qualification') | capitalize }}
          </label>

          <select
            id="1842_profile_qualification"
            ref="qualification"
            v-model="profile.qualification"
            name="profile_qualification"
            data-test="profile-qualification"
            class="styled-select"
          >
            <optgroup
              v-for="country in qualificationList"
              :key="country.label"
              :label="country.label"
            >
              <option
                v-for="option in country.options"
                :key="option.id"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </optgroup>
          </select>
        </div>

        <div class="user-profile__select">
          <label
            v-if="yearList"
            for="1500_profile_year"
            aria-label="Change year of study"
          >
            {{ $t('component.user-profile.labels.year') | capitalize }}
          </label>

          <select
            v-if="yearList"
            id="1500_profile_year"
            ref="year"
            v-model="profile.year"
            name="profile_year"
            data-test="profile-year"
            class="styled-select"
          >
            <option
              v-for="(year, index) in yearList"
              :key="`year-${index}`"
              :value="year.title"
            >
              {{ year.title }}
            </option>
          </select>
        </div>
      </div>

      <button
        id="update-entryreqs"
        data-test="show-entry-requirements"
        :disabled="loading"
        type="submit"
        class="btn btn--inverted btn--fullwidth btn--xsml"
        @click="onSubmit"
      >
        {{
          hasQualification
            ? $t(`component.user-profile.btn.${offeringLevel}`)
            : $t('component.user-profile.btn.exception')
        }}
      </button>
    </form>
    <p v-if="hasQualification" class="text-inline">
      {{ $t('component.user-profile.cant-find.intro') }}
      <a :href="$t('component.user-profile.cant-find.href')">
        {{ $t('component.user-profile.cant-find.link-text') }}
      </a>
    </p>
  </div>
</template>

<script>
import { trackAnalytics } from '~/helpers/client';
import entryReqs from '~/static/data/entryReqs.json';

export default {
  name: 'UserProfile',
  props: {
    onUpdate: {
      type: Function,
      default: () => [{}],
    },
    offeringLevel: {
      type: String,
      default: '',
    },
    offeringCategory: {
      type: String,
      default: '',
    },
    horizontal: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      entryReqs,
      profile: {
        residency: 'aus-citizen',
        qualification: '75',
        year: '',
        method: 'default',
      },
      toUpdate: true,
    };
  },
  computed: {
    residencyValues() {
      return this.$t('component.segmentation.residency.values');
    },
    isUndergraduate() {
      return this.offeringLevel === 'undergraduate';
    },
    hasQualification() {
      const whitelist = ['certificate', 'diploma', 'concurrent diploma'];

      return !whitelist.includes(this.offeringCategory) && this.isUndergraduate;
    },
    yearList() {
      return this.entryReqs.years;
    },
    qualificationList() {
      const validQualifications = this.getValidQualifications();
      const qualificationsbyCountry =
        this.getQualificationsByCountry(validQualifications);
      const qualifications = this.getQualifications(qualificationsbyCountry);

      return this.sortQualificationsAlphabetically(qualifications);
    },
    storeProfile() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    storeProfile() {
      this.updateLocalProfile();
    },
  },
  mounted() {
    // Populate default profile year
    if (this.entryReqs) {
      const currentActiveYear = this.entryReqs.years.filter(
        (year) => year.active,
      );
      this.profile.year = currentActiveYear[0].title;
    }
  },
  methods: {
    updateLocalProfile() {
      this.profile.residency =
        this.storeProfile.residency || this.profile.residency;
      this.profile.qualification =
        this.storeProfile.qualificationCode || this.profile.qualification;
      this.profile.year = this.storeProfile.year || new Date().getFullYear();
    },
    onSubmit() {
      if (this.onUpdate) {
        this.onUpdate({
          residency: this.profile.residency,
          qualificationCode: this.profile.qualification,
          year: this.profile.year,
        });

        // Pass value in residency~qualification~year format
        trackAnalytics('User Profile', 'Click', [
          Object.values(this.profile).join('~'),
        ]);

        // Update segmentation form with selected residency.
        this.$store.dispatch('updateProfile', {
          residency: this.profile.residency,
          method: 'segmented',
          year: this.profile.year,
          qualificationCode: this.profile.qualification,
        });
      }
    },
    getValidQualifications() {
      return this.entryReqs.qualifications.filter((q) => q !== undefined);
    },

    getQualificationsByCountry(validQualifications) {
      return validQualifications.reduce(
        (acc, curVal, idx, a, key = curVal.country) => {
          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(curVal);

          return acc;
        },
        {},
      );
    },

    getQualifications(qualificationsByCountry) {
      const qualificationsByCountryValues = Object.values(
        qualificationsByCountry,
      );
      const qualificationsByCountryResult = Object.keys(
        qualificationsByCountry,
      ).map((country, index) => ({
        label: country,
        options: [
          ...qualificationsByCountryValues[index].map((value) => ({
            label: value.title,
            value: value.id,
          })),
        ],
      }));

      return qualificationsByCountryResult;
    },

    sortQualificationsAlphabetically(qualificationsList) {
      qualificationsList.sort((a, b) => a.label.localeCompare(b.label));

      qualificationsList.forEach((item) => {
        item.options.sort((a, b) => a.label.localeCompare(b.label));
      });

      return qualificationsList;
    },
  },
};
</script>

<style lang="postcss">
.user-profile {
  background-color: var(--col-bg-secondary);
  color: var(--col-text-light);
  padding: 1.5rem;

  &__label,
  label {
    @mixin typography-level-7;

    flex: 1;
    display: block;
    font-weight: var(--fw-semibold);
  }

  &__select {
    margin-bottom: var(--sp-3);
  }

  &--horiz {
    .user-profile__form-inner {
      @media (--bp-desktop) {
        display: flex;
        flex-direction: row;
      }
    }

    .user-profile__select {
      @media (--bp-desktop) {
        padding: 0 0.75rem;
        flex: 1;
      }

      &:first-child {
        @media (--bp-desktop) {
          padding-left: 0;
        }
      }

      &:last-child {
        @media (--bp-desktop) {
          padding-right: 0;
        }
      }
    }

    .text-inline {
      max-width: 100%;

      @media (--bp-desktop) {
        text-align: right;
      }
    }
  }

  /* brand overrides */
  a {
    color: var(--col-link-light);

    &:hover {
      color: var(--col-link-light-active);
    }
  }
}

.text-inline {
  @mixin typography-level-7;

  margin-top: 1rem;
  margin-bottom: 0;
  text-align: left;
}
</style>
