<template>
  <h3
    :is="`h${level}`"
    :class="{
      'header-icon--label': label,
      'header-icon--small': small,
    }"
    class="header-icon"
  >
    <slot />
  </h3>
</template>

<script>
export default {
  props: {
    small: Boolean,
    label: Boolean,
    level: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="postcss">
.header-icon {
  @mixin typography-level-3;

  &__subtitle {
    @mixin typography-default;

    display: block;

    a,
    a:visited {
      font-weight: var(--fw-bold);
    }

    @media (--bp-tablet) {
      display: inline;
    }
  }

  @media (--bp-max-mobile) {
    width: 100%;
  }

  &--small {
    @mixin typography-default;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0;
    margin-bottom: 0;
  }

  &--label {
    @mixin typography-default;

    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
