<template>
  <nav class="bg-alt" aria-label="pagination links">
    <div class="max">
      <div class="cta-buttons">
        <div>
          <ButtonIcon
            v-if="previous"
            icon="arrow-left"
            class="btn--secondary"
            :href="previous.href"
            data-test="cta-button-previous"
          >
            {{ previous.title | capitalize }}
          </ButtonIcon>
        </div>
        <div>
          <ButtonIcon
            v-if="next"
            class="btn--secondary"
            :href="next.href"
            data-test="cta-button-next"
          >
            {{ next.title | capitalize }}
          </ButtonIcon>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ButtonIcon } from '@unimelb/pattern-lib-vue';

export default {
  name: 'CtaButtons',
  components: {
    ButtonIcon,
  },
  props: {
    next: {
      type: [Object, Boolean],
      default: false,
    },
    previous: {
      type: [Object, Boolean],
      default: false,
    },
  },
};
</script>

<style lang="postcss">
.cta-buttons {
  padding-block: var(--sp-4);

  @media (--bp-tablet) {
    display: flex;
    justify-content: space-between;
  }
}
</style>
